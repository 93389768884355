import "./App.css";

import AnnouncementPage from "./pages/AnnouncementPage";
import ProfileSettingPage from "./pages/ProfileSettingPage";
import ExchangeRatePage from "./pages/ExchangeRatePage";
import LoginPage from "./pages/LoginPage";
import AdvertisementPage from "./pages/AdvertisementPage";

import axios from "axios";
import Cookies from "universal-cookie";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setPathAction } from "./store/action";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import ProtectedRoute from "./components/ProtectedRoute"; // Import ProtectedRoute
import MessagesPage from "./pages/MessagesPage";

const App = () => {
  const { REACT_APP_URL } = process.env;

  // states
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [cryptos, setCryptos] = useState([]);
  const [coins, setCoins] = useState([]);
  const [announcements, setAnnouncements] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();
  const cookie = new Cookies();

  // Get token from cookie
  const authCookie = cookie.get("token");

  // Check if the user is already authenticated from cookies on app load
  useEffect(() => {
    if (authCookie) {
      setIsAuthenticated(true); // Set authentication status
    } else {
      setIsAuthenticated(false);
    }
  }, [authCookie]);

  useEffect(() => {
    dispatch(setPathAction(location.pathname));
  }, [location.pathname, dispatch]);

  useEffect(() => {
    const socket = io(`${REACT_APP_URL}`, {
      transports: ["polling"],
    });

    socket.on("getCurrencies", async (data) => {
      const _data = data.currencies;
      setCurrencies(_data);
    });

    socket.on("getCoins", (data) => {
      const _data = data.coins;
      setCoins(_data);
    });

    socket.on("getAnnouncement", (data) => {
      const _data = data.announcement;
      setAnnouncements(_data);
    });

    const getData = async () => {
      try {
        const cryptoRes = await fetch(`${REACT_APP_URL}/currency/crypto`);
        const cryptoData = await cryptoRes?.json();
        setCryptos(cryptoData.currencies);

        const currRes = await fetch(`${REACT_APP_URL}/currency/toman`);
        const currData = await currRes?.json();
        setCurrencies(currData.currencies);

        const coinRes = await fetch(`${REACT_APP_URL}/gold`);
        const coinData = await coinRes?.json();
        setCoins(coinData.goldInTomans);

        const announceRes = await fetch(`${REACT_APP_URL}/announcement`);
        const anounceData = await announceRes?.json();
        setAnnouncements(anounceData.announcements);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [REACT_APP_URL]);

  const authHandler = () => {
    setIsAuthenticated(true);
  };

  const logoutHandler = () => {
    setIsAuthenticated(false);
    cookie.remove("token");
    sessionStorage.removeItem("lastPath"); // Clear last path
    <Navigate to="/" />;
  };

  // Axios interceptor to handle 401 response and logout
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        logoutHandler(); // Logout if API responds with 401
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  const updateCurrencyHandler = async (updatedCurrencies) => {
    try {
      const response = await axios.post(`${REACT_APP_URL}/currency/toman`, {
        currencies: updatedCurrencies,
      });
      setCurrencies(response.data.currencies);
    } catch (error) {
      console.error("Error updating currencies:", error);
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <LoginPage
              isAuthenticated={isAuthenticated}
              authHandler={authHandler}
            />
          }
        />
        {/* Protect routes with ProtectedRoute */}
        <Route
          path="/exchange-rate"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <ExchangeRatePage
                currencies={currencies}
                cryptos={cryptos}
                coins={coins}
                logoutHandler={logoutHandler}
                updateCurrencyHandler={updateCurrencyHandler}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-setting"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <ProfileSettingPage logoutHandler={logoutHandler} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/announcement"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AnnouncementPage
                announcement={announcements}
                logoutHandler={logoutHandler}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/advertisement"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AdvertisementPage logoutHandler={logoutHandler} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messages"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <MessagesPage logoutHandler={logoutHandler} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
